"use client";
import React, { useState, useRef, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";

// import { v4 as uuidv4 } from "uuid";
import axios from "axios";

interface MessageProps {
  sender: string;
  text?: string;
  audioUrl?: string;
  option?: string[];
  messageProps?: {
    filename: string;
    url: string;
    footer: string;
    message_type: string;
  };
  whatsappLink?: string;
  cleanedTextArray?: string[];
}

const Chatbot: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [mode, setMode] = useState("");
  const [uuid, setUuid] = useState("");
  const formData = new FormData();
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [country, setCountry] = useState("");
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("location", position);
          const { latitude, longitude } = position.coords;
          getCountryFromCoordinates(latitude, longitude);
          setUserLocation({ latitude, longitude });
        },

        (error) => {
          console.error("Error get user location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  };

  async function getCountryFromCoordinates(lat: any, lng: any) {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.address && data.address.country) {
        setCountry(data.address.country);
        console.log("data",data);
        return data.address.country;
      } else {
        console.error("Country not found in response.");
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  }

  useEffect(() => {
    getUserLocation();
  }, []);

  const handleToggle = () => {
    setOpen(!open);
    setMode("");
    setMessages([]);
    generateUUID();
  };

  const handleSend = async (option?: string) => {
    console.log("option", option);

    if (inputValue || option) {
      const messageText = option ? option : inputValue;

      // Update messages with the user's message
      setMessages((prev) => [
        ...prev,
        { sender: "You", text: messageText }, // Ensure this matches the MessageProps type
      ]);

      const formData = new FormData(); // Ensure formData is initialized here
      formData.append("workflow_name", "new_chatbot");
      formData.append("text", messageText);
      formData.append("public_id", uuid);
      formData.append("client_id", "81");
      formData.append("lang", "en");
      formData.append("channel", "default");

      setInputValue("");

      try {
        const response = await axios.post(
          "https://dev1.assisto.tech/workflow/execute_workflow",
          formData,
         
          {
            headers: {
              "Content-Type": "multipart/form-data",
             
            },
            params:{
              "country":country,
            }
          }
        );

        console.log("response", response);

        const { whatsappLink, cleanedTextArray } = extractWhatsAppLink(
          response.data.response.New_DM_message
        );
        console.log("whatsappLink", whatsappLink);
        let cleanedText = "";
        if (Array.isArray(cleanedTextArray)) {
          cleanedText = "";
        } else {
          cleanedText = cleanedTextArray;
        }
        if (response.data && response.data.response) {
          setMessages((prev) => [
            ...prev,
            {
              sender: "Bot",
              text: cleanedText,
              option: response.data.response.New_DM_options, // Assuming this is the options array from the response
              messageProps: response.data.response.New_DM_message_props,
              whatsappLink: whatsappLink,
              cleanedTextArray: cleanedTextArray,
            } as MessageProps, // Type assertion here
          ]);
        } else {
          console.error("Error: Invalid response structure");
          setMessages((prev) => [
            ...prev,
            {
              sender: "Bot",
              text: "Sorry, something went wrong.",
            } as MessageProps,
          ]);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessages((prev) => [
          ...prev,
          {
            sender: "Bot",
            text: "Sorry, something went wrong.",
          } as MessageProps,
        ]);
      }

      console.log("messages after update", messages);
    }
  };

  const extractWhatsAppLink = (text: string) => {
    console.log("text", text);

    const urlPattern = /(https?:\/\/[^\s]+)/g; // Regex pattern for URLs
    const matches = text.match(urlPattern); // Find matches in the text

    let whatsappLink: string | null = null;
    let cleanedTextArray: string[] = [];

    if (matches) {
      console.log("matches", matches);

      // Find the first WhatsApp link
      whatsappLink = matches.find((url) => url.includes("wa.me")) || null;

      if (whatsappLink) {
        // Remove the WhatsApp link from the original text
        const cleanedText = text.replace(whatsappLink, "").trim(); // Remove the link and trim spaces

        // Split the cleaned text by '\n'
        cleanedTextArray = cleanedText.split("\n").map((line) => line.trim()); // Split by new line and trim each line

        console.log("cleanedTextArray", cleanedTextArray);
      }
    }

    // If no WhatsApp link is found, just split the original text by '\n'
    if (!whatsappLink) {
      cleanedTextArray = text.split("\n").map((line) => line.trim());
    }

    // Return the extracted WhatsApp link and the cleaned text array
    return { whatsappLink, cleanedTextArray };
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const generateUUID = () => {
    const newUuid = uuidv4(); // Generate a random UUID
    setUuid(newUuid); // Update the state with the new UUID
    console.log("uuid", uuid);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        zIndex: 1000,
        borderRadius: 2,
      }}
    >
      <IconButton
        onClick={handleToggle}
        sx={{
          backgroundColor: "#3894C6",
          color: "white",
          width: 56,
          height: 56,
          borderRadius: "50%",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          "&:hover": { backgroundColor: "#10439F" },
          position: "relative",
          zIndex: open ? 1010 : 1000,
        }}
      >
        {open ? <CloseIcon /> : <ChatIcon />}
      </IconButton>
      {open && (
        <Paper
          elevation={6}
          sx={{
            position: "absolute",
            bottom: isMobile ? 70 : 70,
            right: 0,
            width: isMobile ? "90vw" : 420,
            height: isMobile ? "60vh" : 509,
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // bgcolor: "#BDD4B8",
              p: 1,
              // color: "white",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
              // borderBottom: "4px solid #ECECEC", // Add border bottom color here
              backgroundImage: "url('/headBack.jpg')", // Add your image path here
              backgroundSize: "cover", // Ensure the image covers the box
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src="/sonic.jpg"
              alt="Chatbot Icon"
              width={300}
              height={150}
              style={{ width: "35%", height: "auto" }} // Adjust the width to 60% and auto height
            />
          </Box>

          <Box
            sx={{
              flex: "1 1 auto",
              p: 2,
              backgroundImage: "url('/soni.jpg')", // Add your image path here
              backgroundSize: "cover", // Ensure the image covers the box
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat",
              overflowY: "auto",

              backgroundColor: "#F5F5F5",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#CBA372",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#BDD4B8",
              },
            }}
          >
            {messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: msg.sender === "You" ? "flex-end" : "flex-start",
                  mb: 1,
                }}
              >
                {msg.audioUrl ? (
                  msg.sender === "You" ? (
                    <Box
                      component="audio"
                      controls
                      sx={{
                        bgcolor: msg.sender === "You" ? "#7BC0DF" : "",
                        color: msg.sender === "You" ? "white" : "black",
                        p: 1,
                        borderRadius: 2,
                        maxWidth: "70%",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        wordBreak: "break-word",
                      }}
                    >
                      <source src={msg.audioUrl} type="audio/webm" />
                      Your browser does not support the audio element.
                    </Box>
                  ) : (
                    <>
                      <Box
                        component="audio"
                        controls
                        sx={{
                          bgcolor: msg.sender === "You" ? "#7BC0DF" : "",
                          color: msg.sender === "You" ? "black" : "black",
                          p: 1,
                          borderRadius: 2,
                          maxWidth: "70%",
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                          wordBreak: "break-word",
                        }}
                      >
                        <source
                          src={`data:audio/webm;base64,${msg.audioUrl}`}
                          type="audio/webm"
                        />
                        Your browser does not support the audio element.
                      </Box>

                      {msg.text && (
                        <Typography
                          variant="body1"
                          sx={{
                            bgcolor: msg.sender === "You" ? "#7BC0DF" : "",
                            color: msg.sender === "You" ? "black" : "black",
                            p: 1,
                            borderRadius: 2,
                            maxWidth: "70%",
                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                            wordBreak: "break-word",
                          }}
                        >
                          {msg.text}
                        </Typography>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <Box
                      sx={{
                        backgroundImage:
                          msg.sender === "You"
                            ? "url('/userText.jpg')"
                            : "url('/headBack.jpg')", // Conditional image for 'You' or others
                        backgroundSize: "cover", // Ensure the image covers the area
                        backgroundPosition: "center", // Center the image
                        backgroundRepeat: "no-repeat",
                        color: "black",
                        p: 1,
                        borderRadius: 2,
                        maxWidth: "70%",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        wordBreak: "break-word",
                      }}
                    >
                      {msg.messageProps?.filename ? (
                        // Show file download link if filename exists
                        <>
                          <Typography variant="body1">{msg.text}</Typography>
                          <a
                            href={msg.messageProps.url}
                            download={msg.messageProps.filename}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <span>Download {msg.messageProps.filename}</span>
                            <DownloadIcon sx={{ ml: 1 }} />
                          </a>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", color: "#011f4b", mt: 1 }}
                          >
                            {msg.messageProps.footer}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body1">{msg.text}</Typography>
                      )}
                      {msg.cleanedTextArray &&
                        msg.cleanedTextArray.map((text: any, index: number) => (
                          <Typography key={index} variant="body1">
                            {text}
                          </Typography>
                        ))}
                      {msg.whatsappLink && (
                        <a
                          href={msg.whatsappLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {msg.whatsappLink}
                        </a>
                      )}
                    </Box>
                  </>
                )}

                {msg.option && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    {msg.option.map((opt, optIndex) => (
                      <Button
                        key={optIndex}
                        sx={{
                          bgcolor: msg.sender === "You" ? "#7BC0DF" : "#F5F5DC",
                          // backgroundImage:
                          //   msg.sender === "You"
                          //     ? "url('/userText.jpg')"
                          //     : "url('/button.jpg')", // Conditional image for 'You' or others
                          // backgroundSize: "cover", // Ensure the image covers the button area
                          // backgroundPosition: "center", // Center the image
                          // backgroundRepeat: "no-repeat",
                          color: msg.sender === "You" ? "black" : "black",
                          borderRadius: 2,
                          p: 1,
                          minWidth: "120px",
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => handleSend(opt)}
                      >
                        {opt}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
            ))}

            <div ref={messagesEndRef} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTop: "1px solid #ccc",
              backgroundColor: "white",
              boxShadow: "0px -2px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "0 0 8px 8px",
              padding: "8px", // Added padding to the box
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSend(); // Call handleSend on "Enter" key press
                }
              }}
              sx={{ flex: 1, mr: 1, padding: "4px" }} // Added padding around TextField
              size="small"
            />
            <IconButton
              onClick={() => handleSend(inputValue)} // Call handleSend on button click
              sx={{
                backgroundColor: "#3894C6",
                color: "white",
                "&:hover": { backgroundColor: "#10439F" },
                padding: "8px", // Added padding around SendIcon
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default Chatbot;
